import React from 'react';

const Footer = () => (
  <footer className=" bg-gray-900 text-white">
    <div className="container mx-auto py-16 px-3 ">
      <div className="flex flex-wrap">
        <div className="flex-1 px-3">
          <h2 className="text-lg font-semibold">Sustain Med Network</h2>
          <p className="mt-5">
            Offering inventory management solutions for Canadian health care providers.
          </p>
        </div>
        <div className="flex-1 px-3" />
        <div className="flex-1 px-3">
          <h2 className="text-lg font-semibold">Contact</h2>
          <ul className="mt-4 leading-loose">
            <li>
              <a href="mailto:simon.booker@sustainmednetwork.com">Sustain Med Network</a>
            </li>
            <li>
              <address>Toronto, Ontario</address>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
