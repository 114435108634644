import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';

const Header = () => (
  <header className={`absolute w-full `}>
    <div className="relative container flex flex-col sm:flex-row sm:items-center sm:justify-between mx-auto py-4 px-4 w-full">
      <div className="flex text-2xl">
        <div className="w-12 mr-3">
          <Link to="/">
            <LogoIcon />
          </Link>
        </div>
        <Link to="/">Sustain Med Network</Link>
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#program">
          Program
        </AnchorLink>
        <AnchorLink className="px-4" href="#pre-owned">
          Services
        </AnchorLink>
        <AnchorLink className="px-4" href="#benefits">
          Benefits
        </AnchorLink>
        {/* <AnchorLink className="px-4" href="#testimonials">
          Testimonials
        </AnchorLink> */}
      </div>
      <div className="hidden md:block">
        <AnchorLink className="px-4" href="#contact">
          <Button className="text-sm">Contact Us</Button>
        </AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;
